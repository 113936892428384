<template>
  <div class="row advanced-data-table">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title" v-if="title">{{ $t(title) }}</h4>

          <EbpCard>
            <!-- Tabs -->
            <b-tabs content-class="mt-3" v-if="tabs">
              <b-tab
                :title="$t(item)"
                v-for="(item, i) in tabs"
                :key="i"
                @click="handleTabClick(item)"
              >
              </b-tab>
            </b-tabs>
          </EbpCard>
          <!-- Pagination -->
          <pagination
            v-model="currentPage"
            :total-rows="totalItems || total"
            :per-page="perPage"
            :recordCount="tableData.length"
            :paginate="paginate"
            :showTotal="showTotal"
            :currentPage="currentPage"
          />

          <div
            :class="`filters ${showFilters && 'mb-4'} ${title ? 'mt-4' : ''}`"
            v-if="showFilters"
          >
            <adt-filter v-if="showLimit" :label="$t('show')">
              <b-select
                v-model="perPage"
                :options="pageOptions"
                @input="get"
                class="ml-0"
              ></b-select>
            </adt-filter>

            <!-- Custom filters -->
            <slot name="filters" />

            <!-- Search -->
            <adt-filter v-if="search" :label="$t('search')" align-end>
              <div class="d-flex">
                <form class="w-100" @submit.prevent="q">
                  <b-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control"
                  ></b-input>
                </form>
                <ebp-button size="sm" @click="q" class="ml-1">
                  <i class="bx bx-search"></i>
                </ebp-button>
              </div>
            </adt-filter>
            <!-- End search -->
          </div>

          <!-- Collapsed filters -->
          <transition name="slide">
            <div class="collapsed-filters" v-if="filtersExpanded">
              <slot name="collapsed-filters" />
            </div>
          </transition>

          <adt-filter
            class="more-filters"
            v-if="$scopedSlots['collapsed-filters']"
          >
            <ebp-button
              size="sm"
              :class="{ 'mb-2': true, 'mt-2': filtersExpanded }"
              @click="filtersExpanded = !filtersExpanded"
            >
              <i class="bx bx-dots-horizontal"></i>
              {{ $t(filtersExpanded ? "hide-filters" : "show-more-filters") }}
            </ebp-button>
          </adt-filter>

          <transition name="slide">
            <div class="bulk-filters">
              <slot name="bulk-filters" />
            </div>
          </transition>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table
              :items="tableData"
              :fields="fields"
              responsive="sm"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :busy="loading"
              show-empty
              :empty-text="$t('no-items')"
              @sort-changed="locallyPaginated ? localSort($event) : get($event)"
              v-bind="attrs"
              v-on="$listeners"
              ref="table"
            >
              <!-- Propagate slots -->
              <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
              <template
                v-for="(_, name) in $scopedSlots"
                :slot="name"
                slot-scope="slotData"
                ><slot :name="name" v-bind="slotData"
              /></template>

              <!-- Loader -->
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <spinner />
                </div>
              </template>

              <!-- Actions -->
              <template #cell(actions)="{ item, index }" v-if="!hideActions">
                <slot name="actions" :item="item" :index="index" />
              </template>
            </b-table>
          </div>

          <!-- Pagination -->
          <pagination
            v-model="currentPage"
            :total-rows="totalItems || total"
            :per-page="perPage"
            :recordCount="tableData.length"
            :paginate="paginate"
            :showTotal="showTotal"
            :currentPage="currentPage"
          />

          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { findIndex, mapValues } from "lodash-es";
import AdtFilter from "@/components/core/adt-filter";
import { kebab } from "case";
import Pagination from "./core/Pagination.vue";
export default {
  props: {
    tabs: {},
    sortable: Boolean,
    internalSearch: Boolean,
    totalItems: Number,
    translateHeaders: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ""
    },
    limit: {
      default: 50
    },
    pageOptionsProp: {
      type: Array,
      default: () => [50, 100, 500, 1000]
    },
    sortBy: {
      type: String,
      default: null
    },
    sortDesc: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Array,
      default: () => []
    },
    page: {
      type: Number,
      default: 1
    },
    filterOn: {
      type: Array,
      default: () => []
    },
    search: {
      type: Boolean,
      default: true
    },
    showLimit: {
      type: Boolean,
      default: true
    },
    paginate: {
      type: Boolean,
      default: true
    },
    showTotal: {
      type: Boolean,
      default: true
    },
    showFilters: {
      type: Boolean,
      default: true
    },
    action: {
      type: String
    },
    items: {
      type: Array
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    loadingGlobal: Boolean,
    hideActions: Boolean
  },
  components: {
    AdtFilter,
    Pagination
  },
  data() {
    return {
      tableData: [],
      total: 0,
      filter: null,
      perPage: 0,
      currentPage: 1,
      loading: false,
      isFiltered: false,
      filtersExpanded: false,
      prevParams: null,
      localItems: []
    };
  },
  mounted() {
    this.updateDetails();
    this.updateFilters();
    this.filterizeQuery();

    if (this.$props.items && this.$props.items.length) {
      this.tableData = this.locallyPaginated
        ? this.getPaginatedItems()
        : this.$props.items;
      this.loading = false;
    }
  },
  computed: {
    locallyPaginated() {
      return !this.$props.action && this.$props.paginate;
    },
    attrs() {
      const attrs = {
        ...this.$attrs
      };

      if (this.$props.internalSearch) {
        attrs["filter"] = this.filter;
      }

      return attrs;
    },
    fields() {
      const fields = this.$props.headers.map(f => {
        const object = typeof f === "object";
        const label = (object ? f.label || kebab(f.key) : kebab(f)).replace(
          "-id",
          ""
        );

        return {
          sortable: this.$props.sortable,
          ...f,
          key: object ? f.key : f,
          label: this.$props.translateHeaders ? this.$t(label) : label
        };
      });

      if (!this.$props.hideActions) {
        fields.push({
          key: "actions",
          label: this.$t("actions"),
          sortable: this.$props.sortable
        });
      }

      return fields;
    },
    pageOptions() {
      return this.$props.pageOptionsProp
        .concat([
          !this.$props.pageOptionsProp.includes(
            parseInt(this.$route.query.limit)
          )
            ? this.$route.query.limit
            : null
        ])
        .filter(i => !!i)
        .sort((a, b) => a - b);
    }
  },
  methods: {
    handleTabClick(item) {
      this.$emit("tabClicked", item);
    },
    localSort({ sortBy, sortDesc }) {
      this.$nextTick(() => {
        this.tableData = this.getPaginatedItems(
          [...this.$props.items].sort(
            (a, b) => (sortDesc ? b : a)[sortBy] - (sortDesc ? a : b)[sortBy]
          )
        );
      });
    },
    getPaginatedItems(items = null) {
      if (!items) items = this.$props.items;
      if (!this.locallyPaginated) return items;

      const { perPage, currentPage } = this;
      return (items || []).slice(
        (currentPage - 1) * perPage,
        perPage * currentPage
      );
    },
    updateFilters() {
      this.$emit(
        "update:filters",
        mapValues(
          {
            ...this.$props.filters,
            ...this.$route.query
          },
          (v, k) =>
            Array.isArray(this.filters[k])
              ? v
                ? v.split
                  ? v.split(",")
                  : v
                : v
              : v
        )
      );
      this.perPage = this.$route.query.limit || this.perPage;
      this.filter = this.$route.query.q || this.filter;
    },
    filterizeQuery() {
      const filters = mapValues(
        {
          ...this.$route.query,
          ...this.$props.filters,
          limit: this.perPage === "all" ? null : this.perPage,
          q: this.filter
        },
        v => (Array.isArray(v) ? v.join(",") : v)
      );

      Object.keys(filters).forEach(k => {
        if ([0, "false", "0"].includes(filters[k])) {
          filters[k] = false;
        }
        filters[k] = filters[k] ? filters[k] : undefined;
      });
      this.$router
        .replace({
          query: filters
        })
        .catch(() => {});
    },
    q() {
      if (this.filter) {
        this.get();
        this.isFiltered = true;
      }
    },
    change(k, v, o = null) {
      const index = findIndex(this.tableData, o => o[k] === v);

      if (index !== -1) {
        if (o === null) {
          this.tableData = this.tableData.filter((_, i) => i !== index);
          this.total--;
        } else {
          this.$set(this.tableData, index, o);
        }
      }
    },
    updateDetails() {
      this.perPage = this.$props.limit;
      this.currentPage = this.$props.page;
    },
    onFiltered(filteredItems) {
      this.total = this.$props.totalItems || filteredItems.length;
      this.page = 1;
    },
    async get(params = null) {
      if (!this.$props.action || this.$props.items) {
        return;
      }

      if (params) {
        this.prevParams = params;
      } else {
        params = this.prevParams;
      }

      this.loading = true;
      try {
        const res = await this.$store.dispatch(
          this.action,
          mapValues(
            {
              page: this.currentPage,
              limit: this.perPage,
              search: this.filter,
              ...(typeof params === "object" && params
                ? {
                    sort_by: params.sortBy,
                    sort_order: params.sortDesc
                  }
                : {}),
              ...this.$props.filters
            },
            v => (Array.isArray(v) ? v.join(",") : v)
          )
        );
        this.tableData = res.data.data;
        this.total = res.data.total;
      } catch (err) {
        console.error(err);
      }
      this.loading = false;
    }
  },
  watch: {
    filter(val) {
      this.filterizeQuery();
      if (!val && this.isFiltered) {
        this.get();
        this.isFiltered = false;
      }
    },
    filters: {
      handler() {
        this.filterizeQuery();
        this.get();
      },
      deep: true
    },
    currentPage() {
      this.get();
      if (this.locallyPaginated) this.tableData = this.getPaginatedItems();
    },
    perPage() {
      this.currentPage = 1;
      this.filterizeQuery();
      if (this.locallyPaginated) this.tableData = this.getPaginatedItems();
    },
    items(items) {
      this.tableData = this.locallyPaginated ? this.getPaginatedItems() : items;
    },
    loadingGlobal(bool) {
      this.loading = bool;
    }
  }
};
</script>

<style lang="scss">
.advanced-data-table {
  thead {
    th {
      background: #f0faff;

      &[aria-sort="ascending"],
      &[aria-sort="descending"] {
        outline: 0;
        background: #c9edff;
      }

      vertical-align: middle;
    }
  }

  tbody {
    td {
      vertical-align: middle;
      padding: 0.5rem 0.75rem;
      min-width: 120px !important;
    }

    tr {
      td {
        vertical-align: middle !important;
      }

      &:hover {
        td {
          background: #f8f8f8;
        }
      }
    }

    .badge-bg {
      padding: 0.5rem 0.8rem;
      letter-spacing: 0.41px;
      font-size: 12px;
    }

    .advanced-data-table {
      .dropdown-toggle::after {
        display: none;
      }

      .inline-editing-input {
        margin: 0;
      }
    }
  }

  .custom-select-sm {
    height: calc(1.5em + 0.5rem + 10px);
  }
}
</style>
